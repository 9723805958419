import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'apps/landing-page/src/environments/environment';
import { CookieService } from 'libs/shared-services/src/lib/cookie.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { LandingHiddenService } from '../../services/landing-hidden.service';

@Component({
  selector: 'dashboard-landing',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements AfterViewInit, OnInit {

  public landingHiddenVisible$: Observable<boolean> = this.landingHiddenService.getHiddenVersion$();
  public scrollToSearchInput$: BehaviorSubject<any> = new BehaviorSubject<any>({});

  public deadline = '1 April 2025';

  constructor(
    private cookieService: CookieService,
    private landingHiddenService: LandingHiddenService
  ) {    
  }

  public ngOnInit() {
    this.landingHiddenVisible$.subscribe((value) => {
      if (value) {
        setTimeout(() => {
          this.turnOnBikeAnimation();
        }, 0);
      }
    });
  }

  public ngAfterViewInit(): void {
    this.landingHiddenVisible$.subscribe((value) => {
      if (!value) {
        this.initializeClock('clockdiv', this.deadline);
      }
    });
  }

  public initializeClock(id: string, endtime: any) {
    const clock = document.getElementById(id);
    const daysSpan = clock?.querySelector('.days');
    const hoursSpan = clock?.querySelector('.hours');
    const minutesSpan = clock?.querySelector('.minutes');
    const secondsSpan = clock?.querySelector('.seconds');
    
  
    
    const timeinterval = setInterval(() => {
      this.updateClock(endtime, daysSpan, hoursSpan, minutesSpan, secondsSpan, timeinterval)
    }, 1000);
    this.updateClock(endtime, daysSpan, hoursSpan, minutesSpan, secondsSpan, timeinterval);
  }

  public updateClock(endtime: any, daysSpan: any, hoursSpan: any, minutesSpan: any, secondsSpan: any, timeinterval: any) {
    const t = this.getTimeRemaining(endtime);

    daysSpan.innerHTML = t.days;
    hoursSpan.innerHTML = ('0' + t.hours).slice(-2);
    minutesSpan.innerHTML = ('0' + t.minutes).slice(-2);
    secondsSpan.innerHTML = ('0' + t.seconds).slice(-2);

    if (t.total <= 0) {
      clearInterval(timeinterval);
    }
  }


  public getTimeRemaining(endtime: any){
    const total = Date.parse(endtime) - Date.parse(new Date() as any);
    const seconds = Math.floor( (total/1000) % 60 );
    const minutes = Math.floor( (total/1000/60) % 60 );
    const hours = Math.floor( (total/(1000*60*60)) % 24 );
    const days = Math.floor( total/(1000*60*60*24) );
  
    return {
      total,
      days,
      hours,
      minutes,
      seconds
    };
  }

  

  /*
    Bike animation
  */

  private curve: any;
  private bikeSvg: any;
  private bikeLoadedSvg: any;
  private curveSvg: any;
  private pathLength: any;
  private hasSwitchedBike: boolean;
 
  private turnOnBikeAnimation() {
    this.curve = document.getElementById('curve');
    this.bikeSvg = document.getElementById('bikeSvg');
    this.bikeLoadedSvg = document.getElementById('bikeLoadedSvg');
    
    this.curveSvg = document.getElementById('curveSvg');    
    this.pathLength = this.curve?.getTotalLength();   
    
    this.hasSwitchedBike = false; 

    window.addEventListener('resize', () => {
        // this.resetBike();
    });
    
    this.animateBike();
  }

  private moveBike(progress: any, speed: any) {
    const point = this.curve.getPointAtLength(progress * this.pathLength);

    // The actual dimensions of the curve SVG
    const boundingBox = this.curveSvg.getBoundingClientRect();
    const scaleX = boundingBox.width / 100;
    const scaleY = boundingBox.height / 100;

    // Adjusted coordinates in pixels
    const x = point.x * scaleX;
    const y = point.y * scaleY;

    // Calculation of the rotation angle based on the tangent to the curve
    const delta = 0.001; // Small variation for tangent calculation
    const nextPoint = this.curve.getPointAtLength((progress + delta) * this.pathLength);
    const nextX = nextPoint.x * scaleX;
    const nextY = nextPoint.y * scaleY;

    const angle = Math.atan2(nextY - y, nextX - x) * (180 / Math.PI);

    // Adjusting the speed according to the angle of the curve
    const angleDiff = nextY - y;
    if (angleDiff > 0) {
        // The bike descends, we accelerate slightly
        speed += 0.000003;
    } else if (angleDiff < 0) {
        // The bike climbs, we slow down slightly
        speed -= 0.000001;
    }

    // Limit speed to prevent extreme speeds
    speed = Math.max(0.0005, Math.min(speed, 0.0015));

    this.bikeSvg.style.left = `${x}px`;
    this.bikeSvg.style.top = `${y - 24}px`;
    this.bikeSvg.style.transform = `translate(-50%, -50%) rotate(${angle}deg)`; // Apply the rotation

    this.bikeLoadedSvg.style.left = `${x}px`;
    this.bikeLoadedSvg.style.top = `${y - 24}px`;
    this.bikeLoadedSvg.style.transform = `translate(-50%, -50%) rotate(${angle}deg)`; // Apply the rotation

    // Check if the progress of the bike reached 70% - so then we can change the bike to the loaded one
    if (this.progress >= 0.7 && !this.hasSwitchedBike) {
      this.bikeSvg.style.display = 'none';
      this.bikeLoadedSvg.style.display = 'block';
      this.hasSwitchedBike = true;
   }

    return speed;
  }

  private progress = 0;
  private speed = 0.001; // Initial speed
  
  private animateBike() {
    window.requestAnimationFrame(this.step);
  }

  private step = () => {
    this.speed = this.moveBike(this.progress, this.speed);
    this.progress += this.speed;

    if (this.progress > 1) {
      this.resetBike();
    }

    window.requestAnimationFrame(this.step);
  }

  private resetBike() {
    this.progress = 0;
    this.speed = 0.001; // Initial speed
    // bikes switch
    this.hasSwitchedBike = false; 
    this.bikeSvg.style.display = 'block';
    this.bikeLoadedSvg.style.display = 'none';
    this.pathLength = this.curve?.getTotalLength();   
  }


  public onSearchFoodPress(scrollDistance: "small" | "medium" | "large" = "small") {
    this.scrollToSearchInput$.next({ value: "none" }); // a reset to change the obj instance and re-detect the | async pipe
    setTimeout(() => {
      this.scrollToSearchInput$.next({ value: scrollDistance });
    }, 0);
  }

  public onGooglePlayClick() {
    window.open(environment.MOBILE_GOOGLE_PLAY_URL, '_blank');
  }

  public onAppStoreClick() {
    window.open(environment.MOBILE_APP_STORE_URL, '_blank');
  }


  
}
